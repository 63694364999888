@import url('https://fonts.googleapis.com/css?family=Damion&display=swap');
@media only screen and (min-width : 601px){
.collectionPage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.barre{
    width: 50%;
    margin:0;
}
.category{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2%;
    font-family: Lobster;
    font-size: 1,6em;

}
.category img{
    width: 55%;
    border-radius: 50%;
}
.category img:hover {
    width: 55%;
    border-radius: 45%;
   
}
.picCategory{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
}
.picCollectionWomen{
    width: 20%;
    margin: 2%;
    border: solid;
    border-color: #D6AF26;
}
.picCollectionWomen2{
    width: 40%;
    margin:2%;
    border: solid;
    border-color: #D6AF26;
}



.overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    visibility: hidden;
  }
  .overlay2 {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    visibility: hidden;
  }
  
  .overlay img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin:2%;
  }
  .overlay2 img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
   
  }
  .overlay:target {
    visibility: visible;
    outline: none;
    cursor: default;
  }
  .overlay2:target {
    visibility: visible;
    outline: none;
    cursor: default;
  }
  .picCollectionMen{
    width: 20%;
    margin: 2%;
    border: solid;
    border-color: #D6AF26;
} 
.picCollectionSac{
    width: 22%;
    margin: 2%;
    border: solid;
    border-color: #D6AF26;
}
.collectHomme{
    height: 100vh;
}
.hommeFemme{
    display: flex;
    flex-direction: row
}
.accessoireTissu{
    display:flex;
    flex-direction: row;
}
.tissuCategorie{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.cotonSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/cotonselectedvf.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}

.dentelleSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/dentelleselected.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}
.soieSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/soieselectd.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}
.voileSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/voileselected.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}
.pagne hr{
    color: #D6AF26;
    width: 70%;
    border-color: #D6AF26;
    background-color: #D6AF26;
}
.pagne p{
    font-family: Damion;
    font-size: 1.7em;
    margin:0;
}
.accesSelection{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 2em;
    justify-content:center;
}
.accesSelection p{
    margin-left: 5%;
    margin-right: 5%;
}
.noeudButton{
    background:url('/fondnoeud.jpg');
    background-size: cover;
    background-position: center;
    width: 12vw;
    height: 15vh;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}
.sacButton{
    background:url('/fondsac.jpg');
    background-size: cover;
    background-position: center;
    width: 12vw;
    height: 15vh;
    margin-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}

}
@media only screen and (max-width: 600px){
    .collectionPage{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .category{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2%;
        font-family: Lobster;
        font-size: 1,6em;
    
    }
    .category img{
        width: 80%;
        border-radius: 50%;
    }
    .category img:hover {
        width:80%;
        border-radius: 45%;
       
    }
    .picCategory{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .picCollectionWomen{
        width: 20%;
        margin: 2%;
        border: solid;
        border-color: #D6AF26;
    }
    
    .picCollectionWomen2{
        width: 40%;
        margin:2%;
        border: solid;
        border-color: #D6AF26;
    }
    
    .overlay {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.9);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        visibility: hidden;
      }
      .overlay2 {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.9);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        visibility: hidden;
      }
      
      .overlay img{
        max-width: 100%;
        max-height: 100%;
        width: 98%;
        height: 98%;
        margin:2%;
      }
      .overlay2 img{
        max-width: 100%;
        max-height: 100%;
        width: 47%;
        height: 47%;
        margin:2%;
      }
      .overlay:target {
        visibility: visible;
        outline: none;
        cursor: default;
      }
      .overlay2:target {
        visibility: visible;
        outline: none;
        cursor: default;
      }
      .picCollectionMen{
        width: 20%;
        margin: 2%;
    } 
    .picCollectionSac{
        width: 22%;
        margin: 2%;
    }
    .collectHomme{
        height: 100vh;
    }
    .hommeFemme{
        display: flex;
        flex-direction: row
    }
    .accessoireTissu{
        display:flex;
        flex-direction: row;
    }
    .tissuCategorie{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .cotonSelection{
        width:60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/cotonselectedvf.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .dentelleSelection{
        width: 60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/dentelleselected.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .soieSelection{
        width: 60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/soieselectd.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .voileSelection{
        width: 60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/voileselected.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
}
.tissu{
    height: auto;
}
.pagne hr{
    color: #D6AF26;
    width: 70%;
    border-color: #D6AF26;
    background-color: #D6AF26;
}
.pagne p{
    font-family: Damion;
    font-size: 1.5em;
}
.barre{
    width: 70%;
    margin:0;
}
.accesSelection{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.2em;
    justify-content:center;
}
.accesSelection p{
    margin-left: 5%;
    margin-right: 5%;
}
.noeudButton{
    background:url('/fondnoeud.jpg');
    background-size: cover;
    background-position: center;
    width: 28vw;
    height: 20vh;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}
.sacButton{
    background:url('/fondsac.jpg');
    background-size: cover;
    background-position: center;
    width: 28vw;
    height: 20vh;
    margin-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}
.overlay2:target {
    visibility: visible;
    outline: none;
    cursor: default;
  }
  .overlay2 {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    visibility: hidden;
  }
  .overlay2 img{
    max-width: 100%;
    max-height: 100%;
    width: 50%;
    height: 50%;
   
  }
}