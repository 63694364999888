
@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');

@media only screen and (min-width : 601px){

.presentationPic{
    width: 15%;
    margin: 2%;
    border-radius: 5%;
    height: 100%;
    margin-right: 10%;
   
}
.mainpage h1{
    font-family: lobster;
}
.mainpage h2{
    font-family: lobster;
    font-size: 1.9em;
    color: white;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
}
.mainpage h3{
    font-family: lobster;
    font-size: 1.3em;
    margin:0;
    white-space: normal;
}
.paragraphe{
    display: flex;
    flex-direction: row;
    text-align: justify;
    width: 50%;
}
.presentationPicSecond{
    width: 96%;
    margin: 2%;
    border-radius: 3%;
   
}
.presentation{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: justify;
    
}
.leftConteneur{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.presentationSecond{
    display: flex;
    flex-direction:row;
    width: 96%;
    height: 100%;
    justify-content: center;
  
    
}
.mainpage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: normal;
}
.mainpage strong{
    color: black;
    text-shadow: #ffffff 1px 1px, #ffffff -1px 1px, #ffffff -1px -1px, #ffffff 1px -1px;

}
.mainpage strong:hover{
    color: white;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
    font-size: 1.4em;
}
.presentationText{
    display: flex;
    justify-content: center;

    flex-direction: row;
    width: 100%;
    font-family: lobster;
    font-size: 1em;
    text-align: justify;
}
.presentationText2{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 42%;
    font-family: lobster;
    font-size: 1.4em;
    text-align: justify;
   
    margin-right: 5%;
}
.presentationText3{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 80%;
    font-family: lobster;
    font-size: 1.4em;
    text-align: justify;
    margin:1%;
}
}
@media only screen and (max-width: 600px){
    .presentationText{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 90%;
    }
    .presentationText2{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        width: 90%;
        font-family: lobster;
       
        text-align: justify;
        margin:2%;

    }
    .presentationText3{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        width: 90%;
        font-family: lobster;
       
        text-align: justify;
        margin:2%;

    }
    .presentationPic{
        width: 80%;
        border-radius: 5%;
    }
    .presentation{
        display: flex;
        flex-direction: row;
        font-size: 1em;
        width: 100%;
        height: auto;
        margin: 2%;
        justify-content: center;
        align-items: center; 
        font-family: lobster;
    }
    .mainpage{
        font-family: lobster;
        font-size: 1em;
        margin: 2%;
        white-space: normal;
    }
    .presentationPicSecond{
        width: 80%;
        margin: 2%;
        border-radius: 3%;
       
    }
    .mainpage h1{
        font-family: lobster;
    }
    .paragraphe{
        display: flex;
        flex-direction: column;
        text-align: justify;
    }
    .mainpage h2{
        font-family: lobster;
    }
}
