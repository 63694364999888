
@media only screen and (min-width : 601px){
.footer{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.6em;
}
.ousman{
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.ovh{
    width:50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.ousman p{
    margin: 1%;
}
.ovh p{
    margin: 1%;
}
.ovh a{
    text-decoration: none;
    color:white;
}
.ovh img {
    width: 3%;
}
}

@media only screen and (max-width : 601px){
    .footer{
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 0.6em;
    }
    .ousman{
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .ovh{
        width:50%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .ousman p{
        margin: 1%;
    }
    .ovh p{
        margin: 1%;
    }
    .ovh a{
        text-decoration: none;
        color:white;
    }
    .ovh img {
        width: 3%;
    }
}