@import url(https://fonts.googleapis.com/css?family=Damion&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css?family=Damion&display=swap);
@import url(https://fonts.googleapis.com/css?family=Damion&display=swap);
@media only screen and (min-width : 601px){
.App {
  text-align: center;
  background: url('/fond(3).jpg') fixed;
  background-repeat:repeat;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
  margin: 0;
  padding:0;

}




}
@media only screen and (max-width : 600px){
  .App {
    text-align: center;
    background: url('/fond(3).jpg') fixed;
    background-repeat:no-repeat;
    background-size: cover;
    max-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding:0;
  
  }
  
  }

@media only screen and (min-width : 601px){
    .versionMobile{
        display: none;
    }
.trioPicAndMovie{
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.filtreBackground{
    width: 100%;
    height: auto;
    z-index: 5;
}
.picLeft {
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    -webkit-filter: sepia(1);
            filter: sepia(1)
}
.picRight {
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    right : 0px;
    -webkit-filter: sepia(1);
            filter: sepia(1)
}
.picLeft:hover{
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    -webkit-filter: sepia(0);
            filter: sepia(0)
}
.picRight:hover{
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    -webkit-filter: sepia(0);
            filter: sepia(0)
}
  .video-background {
      display: flex;
      flex-direction: row;
    background: #000;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    }

.videoacceuil {
    position: absolute;
    top: 0;
    left: 0;
    width: 10%;
    pointer-events: none;
}




.picCenter{
    position: absolute;
    top: 0%;
    z-index: 49;
    left: 30%;
    width: 40%;
}
.button{
    border: solid;
    border-radius: 50%;
    border-color: #D6AF26;
    position: absolute;
    bottom: 3%;
    width : 10%;
    font-size: 20px;
    left: 42%;
    color : #D6AF26;
    padding : 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
    text-decoration: none;
    z-index:51;
}

.load{
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-image: url('/photoloading.jpg');
}

.text-base{
    opacity:1;
 }
 
 .text-hover{
    display:none;
 }
 
 .button:hover span.text-base{
    opacity:0;
 }

 .button:hover span.text-hover{
    display:flex;
    position:absolute;
    align-items: center;
    justify-content: center;
 }
}
@media only screen and (max-width: 600px){
    .video-background{
       display: none;
    }
  .filtreBackground{
      display:none;
  }
  .picCenterMobile{
      width: 100%;
      margin-left: 2%;
  }
  .versionMobile{
    text-align: center;
    width: 100%;
    height: 100%;
    max-width:100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .button{
    border: solid;
    border-radius: 50%;
    border-color: #D6AF26;
    position: absolute;
    bottom: 7%;
    left:25%;
    width : 40%;
    font-size: 28px;
    color : #D6AF26;
    padding : 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
    text-decoration: none;
}
.load{
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-image: url('/photoloading.jpg');
}

    
}
@media only screen and (min-width : 601px){

.navbarDesktop{
    display: flex;
    font-size: 1.6em;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: Damion;
    z-index: 100;
    top: 0;
}
.navbarMobile{
    display: none;
}
.navbarSelection{
    margin: 2%;
    text-decoration: none;
    color: black;
}
.navbarSelection:hover{
    margin: 2%;
    text-decoration: none;
    color: #D6AF26;
    
}
.logoNavbar{
    width: 70%;
}
}
@media only screen and (max-width : 600px){
.navbarDesktop{
    display: none;;
}
.logoNavbar{
    width: 60%;
}
.burger{
    width:20%;
    position: absolute;
    right: 0%;
    top:0%;
}
.navbarMobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.navbarBurger{
    position:fixed;
    top:0%;
    right:0%;
    display: flex;
    flex-direction:row;
    font-family: Damion;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 30%;
    background-color : rgba(214,175,38,0.60);
    z-index: 50;
    flex-wrap: wrap;
   
}
.navbarSelection{
    margin: 3%;
    text-decoration: none;
    color: black;
    font-size:1em;
    padding: 1%;
    color:  black;

}

}

@media only screen and (min-width : 601px){

.presentationPic{
    width: 15%;
    margin: 2%;
    border-radius: 5%;
    height: 100%;
    margin-right: 10%;
   
}
.mainpage h1{
    font-family: lobster;
}
.mainpage h2{
    font-family: lobster;
    font-size: 1.9em;
    color: white;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
}
.mainpage h3{
    font-family: lobster;
    font-size: 1.3em;
    margin:0;
    white-space: normal;
}
.paragraphe{
    display: flex;
    flex-direction: row;
    text-align: justify;
    width: 50%;
}
.presentationPicSecond{
    width: 96%;
    margin: 2%;
    border-radius: 3%;
   
}
.presentation{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: justify;
    
}
.leftConteneur{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.presentationSecond{
    display: flex;
    flex-direction:row;
    width: 96%;
    height: 100%;
    justify-content: center;
  
    
}
.mainpage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: normal;
}
.mainpage strong{
    color: black;
    text-shadow: #ffffff 1px 1px, #ffffff -1px 1px, #ffffff -1px -1px, #ffffff 1px -1px;

}
.mainpage strong:hover{
    color: white;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
    font-size: 1.4em;
}
.presentationText{
    display: flex;
    justify-content: center;

    flex-direction: row;
    width: 100%;
    font-family: lobster;
    font-size: 1em;
    text-align: justify;
}
.presentationText2{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 42%;
    font-family: lobster;
    font-size: 1.4em;
    text-align: justify;
   
    margin-right: 5%;
}
.presentationText3{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    width: 80%;
    font-family: lobster;
    font-size: 1.4em;
    text-align: justify;
    margin:1%;
}
}
@media only screen and (max-width: 600px){
    .presentationText{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 90%;
    }
    .presentationText2{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        width: 90%;
        font-family: lobster;
       
        text-align: justify;
        margin:2%;

    }
    .presentationText3{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        width: 90%;
        font-family: lobster;
       
        text-align: justify;
        margin:2%;

    }
    .presentationPic{
        width: 80%;
        border-radius: 5%;
    }
    .presentation{
        display: flex;
        flex-direction: row;
        font-size: 1em;
        width: 100%;
        height: auto;
        margin: 2%;
        justify-content: center;
        align-items: center; 
        font-family: lobster;
    }
    .mainpage{
        font-family: lobster;
        font-size: 1em;
        margin: 2%;
        white-space: normal;
    }
    .presentationPicSecond{
        width: 80%;
        margin: 2%;
        border-radius: 3%;
       
    }
    .mainpage h1{
        font-family: lobster;
    }
    .paragraphe{
        display: flex;
        flex-direction: column;
        text-align: justify;
    }
    .mainpage h2{
        font-family: lobster;
    }
}

@media only screen and (min-width : 601px){
.talkaboutusPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: lobster;
}


.videoFrance3{
    margin-top: 2%;
    
}
.firstReportage{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: space-between;
    margin-bottom: 2%;
    border: solid;
    border-color: #D6AF26;
}
.firstReportage p {
    font-size: 1.2em;
    
}
}
@media only screen and (max-width : 600px){
    .talkaboutusPage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: lobster;
    }
    
    
    .videoFrance3{
        margin: 2%;
        width: 90%;
        height: 100%;
        
    }
    .firstReportage{
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: space-between;
        margin-bottom: 2%;
        border: solid;
        border-color: #D6AF26;
    }
    .firstReportage p {
        font-size: 1.2em;
        
    }
    }
@media only screen and (min-width : 601px){
.collectionPage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.barre{
    width: 50%;
    margin:0;
}
.category{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2%;
    font-family: Lobster;
    font-size: 1,6em;

}
.category img{
    width: 55%;
    border-radius: 50%;
}
.category img:hover {
    width: 55%;
    border-radius: 45%;
   
}
.picCategory{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
}
.picCollectionWomen{
    width: 20%;
    margin: 2%;
    border: solid;
    border-color: #D6AF26;
}
.picCollectionWomen2{
    width: 40%;
    margin:2%;
    border: solid;
    border-color: #D6AF26;
}



.overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    visibility: hidden;
  }
  .overlay2 {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    visibility: hidden;
  }
  
  .overlay img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    margin:2%;
  }
  .overlay2 img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
   
  }
  .overlay:target {
    visibility: visible;
    outline: none;
    cursor: default;
  }
  .overlay2:target {
    visibility: visible;
    outline: none;
    cursor: default;
  }
  .picCollectionMen{
    width: 20%;
    margin: 2%;
    border: solid;
    border-color: #D6AF26;
} 
.picCollectionSac{
    width: 22%;
    margin: 2%;
    border: solid;
    border-color: #D6AF26;
}
.collectHomme{
    height: 100vh;
}
.hommeFemme{
    display: flex;
    flex-direction: row
}
.accessoireTissu{
    display:flex;
    flex-direction: row;
}
.tissuCategorie{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.cotonSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/cotonselectedvf.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}

.dentelleSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/dentelleselected.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}
.soieSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/soieselectd.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}
.voileSelection{
    width: 25%;
    background-size: cover;
    background-position: center;
    height: 100%;
    background-image: url('/voileselected.jpg');
    margin: 2%;
    font-family: Damion;
    color: white;
    border-radius: 50%;
    font-size: 1.3em;
}
.pagne hr{
    color: #D6AF26;
    width: 70%;
    border-color: #D6AF26;
    background-color: #D6AF26;
}
.pagne p{
    font-family: Damion;
    font-size: 1.7em;
    margin:0;
}
.accesSelection{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 2em;
    justify-content:center;
}
.accesSelection p{
    margin-left: 5%;
    margin-right: 5%;
}
.noeudButton{
    background:url('/fondnoeud.jpg');
    background-size: cover;
    background-position: center;
    width: 12vw;
    height: 15vh;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}
.sacButton{
    background:url('/fondsac.jpg');
    background-size: cover;
    background-position: center;
    width: 12vw;
    height: 15vh;
    margin-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}

}
@media only screen and (max-width: 600px){
    .collectionPage{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .category{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2%;
        font-family: Lobster;
        font-size: 1,6em;
    
    }
    .category img{
        width: 80%;
        border-radius: 50%;
    }
    .category img:hover {
        width:80%;
        border-radius: 45%;
       
    }
    .picCategory{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .picCollectionWomen{
        width: 20%;
        margin: 2%;
        border: solid;
        border-color: #D6AF26;
    }
    
    .picCollectionWomen2{
        width: 40%;
        margin:2%;
        border: solid;
        border-color: #D6AF26;
    }
    
    .overlay {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.9);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        visibility: hidden;
      }
      .overlay2 {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.9);
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        visibility: hidden;
      }
      
      .overlay img{
        max-width: 100%;
        max-height: 100%;
        width: 98%;
        height: 98%;
        margin:2%;
      }
      .overlay2 img{
        max-width: 100%;
        max-height: 100%;
        width: 47%;
        height: 47%;
        margin:2%;
      }
      .overlay:target {
        visibility: visible;
        outline: none;
        cursor: default;
      }
      .overlay2:target {
        visibility: visible;
        outline: none;
        cursor: default;
      }
      .picCollectionMen{
        width: 20%;
        margin: 2%;
    } 
    .picCollectionSac{
        width: 22%;
        margin: 2%;
    }
    .collectHomme{
        height: 100vh;
    }
    .hommeFemme{
        display: flex;
        flex-direction: row
    }
    .accessoireTissu{
        display:flex;
        flex-direction: row;
    }
    .tissuCategorie{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .cotonSelection{
        width:60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/cotonselectedvf.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .dentelleSelection{
        width: 60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/dentelleselected.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .soieSelection{
        width: 60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/soieselectd.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .voileSelection{
        width: 60px;
        background-size: cover;
        background-position: center;
        height: 90px;
        background-image: url('/voileselected.jpg');
        margin: 2%;
        font-family: Damion;
        color: white;
        border-radius: 50%;
        font-size: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
}
.tissu{
    height: auto;
}
.pagne hr{
    color: #D6AF26;
    width: 70%;
    border-color: #D6AF26;
    background-color: #D6AF26;
}
.pagne p{
    font-family: Damion;
    font-size: 1.5em;
}
.barre{
    width: 70%;
    margin:0;
}
.accesSelection{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 1.2em;
    justify-content:center;
}
.accesSelection p{
    margin-left: 5%;
    margin-right: 5%;
}
.noeudButton{
    background:url('/fondnoeud.jpg');
    background-size: cover;
    background-position: center;
    width: 28vw;
    height: 20vh;
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}
.sacButton{
    background:url('/fondsac.jpg');
    background-size: cover;
    background-position: center;
    width: 28vw;
    height: 20vh;
    margin-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}
.overlay2:target {
    visibility: visible;
    outline: none;
    cursor: default;
  }
  .overlay2 {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    visibility: hidden;
  }
  .overlay2 img{
    max-width: 100%;
    max-height: 100%;
    width: 50%;
    height: 50%;
   
  }
}
@media only screen and (min-width : 601px){

.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
}
.contact p{
    font-family: Lobster;
    font-size: 1.5em;
}

.firstPicSocial{
    display: flex;
    flex-direction: row;
}

.secondPicSocial{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
    
}
.contact img {
    width: 10%;
    margin: 2%;
}

.contact img:hover {
    width: 11%;
    margin: 1.5%;
}
.mail{
    border: solid ;
    width: 40%;
    border-radius: 0%;
}

}
@media only screen and (max-width : 600px){
    .contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        
    }
    .contact p{
        font-family: Lobster;
        font-size: 1.5em;
    }
    
    .firstPicSocial{
        display: flex;
        flex-direction: row;
    }
    
    .secondPicSocial{
        display: flex;
        flex-direction: row;
        margin-bottom: 5%;
        
    }
    .contact img {
        width: 10%;
        margin: 2%;
    }
    
    .contact img:hover {
        width: 11%;
        margin: 1.5%;
    }
    .mail{
        border: solid ;
        width: 80%;
        border-radius: 0%;
    }
}

@media only screen and (min-width : 601px){
.footer{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.6em;
}
.ousman{
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.ovh{
    width:50%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.ousman p{
    margin: 1%;
}
.ovh p{
    margin: 1%;
}
.ovh a{
    text-decoration: none;
    color:white;
}
.ovh img {
    width: 3%;
}
}

@media only screen and (max-width : 601px){
    .footer{
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 0.6em;
    }
    .ousman{
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .ovh{
        width:50%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .ousman p{
        margin: 1%;
    }
    .ovh p{
        margin: 1%;
    }
    .ovh a{
        text-decoration: none;
        color:white;
    }
    .ovh img {
        width: 3%;
    }
}
@media only screen and (min-width : 601px){
.picAtelierPortrait{
    width: 25%;
    margin: 2%;
}
.picAtelierPaysage{
    width: 45%;
    margin: 2%;
}
.acceuilAtelier{
    width: 60%;
    height: 100%;
    background-image: url('/atelier1.jpg');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 5%;
    left: 20%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-family: Damion;
    font-size: 2em;
    text-shadow: black 1px 1px, black -1px -1px, black 1px -1px;;
}

}

@media only screen and (max-width : 600px){
    .picAtelierPortrait{
        width: 25%;
        margin: 2%;
    }
    .picAtelierPaysage{
        width: 45%;
        margin: 2%;
    }
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

