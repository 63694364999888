@media only screen and (min-width : 601px){
.talkaboutusPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: lobster;
}


.videoFrance3{
    margin-top: 2%;
    
}
.firstReportage{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: space-between;
    margin-bottom: 2%;
    border: solid;
    border-color: #D6AF26;
}
.firstReportage p {
    font-size: 1.2em;
    
}
}
@media only screen and (max-width : 600px){
    .talkaboutusPage{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: lobster;
    }
    
    
    .videoFrance3{
        margin: 2%;
        width: 90%;
        height: 100%;
        
    }
    .firstReportage{
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: space-between;
        margin-bottom: 2%;
        border: solid;
        border-color: #D6AF26;
    }
    .firstReportage p {
        font-size: 1.2em;
        
    }
    }