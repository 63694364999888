
@media only screen and (min-width : 601px){
    .versionMobile{
        display: none;
    }
.trioPicAndMovie{
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.filtreBackground{
    width: 100%;
    height: auto;
    z-index: 5;
}
.picLeft {
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    filter: sepia(1)
}
.picRight {
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    right : 0px;
    filter: sepia(1)
}
.picLeft:hover{
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    filter: sepia(0)
}
.picRight:hover{
    width: 30%;
    height: 100%;
    z-index: 50;
    position: absolute;
    filter: sepia(0)
}
  .video-background {
      display: flex;
      flex-direction: row;
    background: #000;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    }

.videoacceuil {
    position: absolute;
    top: 0;
    left: 0;
    width: 10%;
    pointer-events: none;
}




.picCenter{
    position: absolute;
    top: 0%;
    z-index: 49;
    left: 30%;
    width: 40%;
}
.button{
    border: solid;
    border-radius: 50%;
    border-color: #D6AF26;
    position: absolute;
    bottom: 3%;
    width : 10%;
    font-size: 20px;
    left: 42%;
    color : #D6AF26;
    padding : 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
    text-decoration: none;
    z-index:51;
}

.load{
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-image: url('/photoloading.jpg');
}

.text-base{
    opacity:1;
 }
 
 .text-hover{
    display:none;
 }
 
 .button:hover span.text-base{
    opacity:0;
 }

 .button:hover span.text-hover{
    display:flex;
    position:absolute;
    align-items: center;
    justify-content: center;
 }
}
@media only screen and (max-width: 600px){
    .video-background{
       display: none;
    }
  .filtreBackground{
      display:none;
  }
  .picCenterMobile{
      width: 100%;
      margin-left: 2%;
  }
  .versionMobile{
    text-align: center;
    width: 100%;
    height: 100%;
    max-width:100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .button{
    border: solid;
    border-radius: 50%;
    border-color: #D6AF26;
    position: absolute;
    bottom: 7%;
    left:25%;
    width : 40%;
    font-size: 28px;
    color : #D6AF26;
    padding : 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: #000000 1px 1px, #000000 -1px 1px, #000000 -1px -1px, #000000 1px -1px;
    text-decoration: none;
}
.load{
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    height: 100vh;
    background-image: url('/photoloading.jpg');
}

    
}