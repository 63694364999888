@import url('https://fonts.googleapis.com/css?family=Damion&display=swap');
@media only screen and (min-width : 601px){

.navbarDesktop{
    display: flex;
    font-size: 1.6em;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: Damion;
    z-index: 100;
    top: 0;
}
.navbarMobile{
    display: none;
}
.navbarSelection{
    margin: 2%;
    text-decoration: none;
    color: black;
}
.navbarSelection:hover{
    margin: 2%;
    text-decoration: none;
    color: #D6AF26;
    
}
.logoNavbar{
    width: 70%;
}
}
@media only screen and (max-width : 600px){
.navbarDesktop{
    display: none;;
}
.logoNavbar{
    width: 60%;
}
.burger{
    width:20%;
    position: absolute;
    right: 0%;
    top:0%;
}
.navbarMobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.navbarBurger{
    position:fixed;
    top:0%;
    right:0%;
    display: flex;
    flex-direction:row;
    font-family: Damion;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 30%;
    background-color : rgba(214,175,38,0.60);
    z-index: 50;
    flex-wrap: wrap;
   
}
.navbarSelection{
    margin: 3%;
    text-decoration: none;
    color: black;
    font-size:1em;
    padding: 1%;
    color:  black;

}

}