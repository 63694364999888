@media only screen and (min-width : 601px){

.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    
}
.contact p{
    font-family: Lobster;
    font-size: 1.5em;
}

.firstPicSocial{
    display: flex;
    flex-direction: row;
}

.secondPicSocial{
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
    
}
.contact img {
    width: 10%;
    margin: 2%;
}

.contact img:hover {
    width: 11%;
    margin: 1.5%;
}
.mail{
    border: solid ;
    width: 40%;
    border-radius: 0%;
}

}
@media only screen and (max-width : 600px){
    .contact{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        
    }
    .contact p{
        font-family: Lobster;
        font-size: 1.5em;
    }
    
    .firstPicSocial{
        display: flex;
        flex-direction: row;
    }
    
    .secondPicSocial{
        display: flex;
        flex-direction: row;
        margin-bottom: 5%;
        
    }
    .contact img {
        width: 10%;
        margin: 2%;
    }
    
    .contact img:hover {
        width: 11%;
        margin: 1.5%;
    }
    .mail{
        border: solid ;
        width: 80%;
        border-radius: 0%;
    }
}