@media only screen and (min-width : 601px){
.App {
  text-align: center;
  background: url('/fond(3).jpg') fixed;
  background-repeat:repeat;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
  margin: 0;
  padding:0;

}




}
@media only screen and (max-width : 600px){
  .App {
    text-align: center;
    background: url('/fond(3).jpg') fixed;
    background-repeat:no-repeat;
    background-size: cover;
    max-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding:0;
  
  }
  
  }